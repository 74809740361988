import './css/main.css';
import './js/site.js';
import '../node_modules/hamburgers/dist/hamburgers.min.css';

import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';


var LazyLoad = require('vanilla-lazyload');

var ScrollMagic = require('scrollmagic');

var SmoothScroll = require('smooth-scroll');


import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";

import Plyr from 'plyr';

// import '../node_modules/plyr/dist/plyr.css';


// Add functions to global

window.playbutton = playbutton;




// Open menu

function openMenu() {
        document.getElementById("menu").classList.toggle("menu-active");
        document.getElementById("menu-button").classList.toggle("rotate-180");
        document.getElementById("menu-button-top").classList.toggle("rotate-45");
        document.getElementById("menu-button-bottom").classList.toggle("-rotate-45");
        document.getElementById("menu-button-middle").classList.toggle("opacity-0");
    };

function openMenuVideo() {
document.getElementById("menu").classList.toggle("menu-active");
document.getElementById("menu-button-triangle").classList.toggle("text-youngster-black");
document.getElementById("menu-button-triangle").classList.toggle("text-youngster-orange");
document.getElementById("menu-button").classList.toggle("rotate-180");
document.getElementById("menu-button-top").classList.toggle("rotate-45");
document.getElementById("menu-button-bottom").classList.toggle("-rotate-45");
document.getElementById("menu-button-middle").classList.toggle("opacity-0");
};
    
    window.openMenu = openMenu;
    window.openMenuVideo = openMenuVideo;





// Custom player
const player = new Plyr('#player', {
        controls: [
        // 'play-large', // The large play button in the center
        // 'restart', // Restart playback
        //       'rewind', // Rewind by the seek time (default 10 seconds)
              'play', // Play/pause playback
        //       'fast-forward', // Fast forward by the seek time (default 10 seconds)
              'progress', // The progress bar and scrubber for playback and buffering
              'current-time', // The current time of playback
              'duration', // The full duration of the media
              'mute', // Toggle mute
              'volume', // Volume control
              'captions', // Toggle captions
        //       'settings', // Settings menu
              'pip', // Picture-in-picture (currently Safari only)
              'airplay', // Airplay (currently Safari only)
        //       'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
              'fullscreen' // Toggle fullscreen
        ],
        autoplay: true,
        clickToPlay: true,
        fullscreen: { enabled: true, fallback: true, iosNative: true }
});

function playbutton() {
        player.togglePlay();

        // var buttontext = document.getElementById("play-button-text");

        // console.log(buttontext);

        // if (buttontext.innerHTML === "Play") {
        //         buttontext.innerHTML = "Stop";
        // } else if (buttontext.innerHTML === "Stop") {
        //         buttontext.innerHTML = "Play";
        // } else false;
};






//  Initialise smooth scrolling to all anchor links
var scroll = new SmoothScroll('a[href*="#"]');





let controller = new ScrollMagic.Controller();

const scrollervideos = document.querySelectorAll(".thescrollvideo");
if (typeof(scrollervideos) != 'undefined' && scrollervideos != null) {
	for (let i = 0, len = scrollervideos.length; i < len; i++) {



        let videotitle = scrollervideos[i].getAttribute("data-title");
        let videobrand = scrollervideos[i].getAttribute("data-brand");
        let videoid = scrollervideos[i].getAttribute("data-postid");

        let videotitlenospace = videotitle.replace(/\s/g, "");
        let videobrandnospace = videobrand.replace(/\s/g, "");

        let thetrigger = '#video-' + videoid;

        if (videobrand != "") {
                new ScrollMagic.Scene({
                                        triggerElement: thetrigger,// y value not modified, so we can use element as trigger as well
                                        duration: '100%'
                                                })

                                                .on("enter", function () {document.getElementById('caption').innerHTML = '&lsquo;' + videotitle + '&rsquo; — ' + videobrand})
                                                .on("enter", function () {document.getElementById('video-' + videoid).play()})
                                                .on("leave", function () {document.getElementById('video-' + videoid).pause()})
                                                
                                                // .addIndicators({name: "Video " })
                                                
                                                .addTo(controller)
        
        } else {
                
                new ScrollMagic.Scene({
                        triggerElement: thetrigger,// y value not modified, so we can use element as trigger as well
                        duration: '100%'
                                })

                                .on("enter", function () {document.getElementById('caption').innerHTML = videotitle})
                                .on("enter", function () {document.getElementById('video-' + videoid).play()})
                                .on("leave", function () {document.getElementById('video-' + videoid).pause()})
                                
                                // .addIndicators({name: "Video " })
                                
                                .addTo(controller)

        }




        

};
};







var montagereel = document.getElementById("montagereel");

if (typeof(montagereel) != 'undefined' && montagereel != null) {

        new ScrollMagic.Scene({
                triggerElement: '#montagereel',// y value not modified, so we can use element as trigger as well
                duration: '100%'
                        })

                        .on("enter", function () {document.getElementById('montagereel').play()})
                        .on("leave", function () {document.getElementById('montagereel').pause()})
                        
                        // .addIndicators({name: "Video " })
                        
                        .addTo(controller)

        new ScrollMagic.Scene({
                triggerElement: '#montagereel',
                duration: '100%'// y value not modified, so we can use element as trigger as well
                        })

                        .on("enter", function () {document.getElementById('caption').classList.add("opacity-0")})
                        .on("leave", function () {document.getElementById('caption').classList.remove("opacity-0")})
                        .addTo(controller); 

} else false;


var frontreel = document.getElementById("frontreel");

if (typeof(frontreel) != 'undefined' && frontreel != null) {

        new ScrollMagic.Scene({
                triggerElement: '#frontreel',// y value not modified, so we can use element as trigger as well
                duration: '100%'
                        })

                        .on("enter", function () {document.getElementById('frontreel').play()})
                        .on("leave", function () {document.getElementById('frontreel').pause()})
                        
                        // .addIndicators({name: "Video " })
                        
                        .addTo(controller)

} else false;




var homepagereel = document.getElementById("homepage-montagereelcontainer");
if (typeof(homepagereel) != 'undefined' && homepagereel != null) {
        new ScrollMagic.Scene({
                triggerElement: '#homepage-montagereelcontainer',
                triggerHook: 0.1,
                duration: '100%'
                        })

                        .on("enter", function () {document.getElementById('arrow1').classList.remove("opacity-50");
                                                        document.getElementById('production-header').classList.remove("md:opacity-0");
                                                        document.getElementById('logo-link').classList.add("pointer-events-auto");
                                                        document.getElementById('production-header').classList.remove("homepage-flipper");
                                                        

                                                })
                        .on("leave", function () {document.getElementById('arrow1').classList.add("opacity-50");
                        document.getElementById('production-header').classList.add("homepage-flipper");
                        document.getElementById('production-header').classList.add("md:opacity-0");
                })
                        
                        // .addIndicators({name: "Video " })
                        
                        .addTo(controller)
}

var homepagecolour = document.getElementById("colour_carousel-swiper-container");
if (typeof(homepagecolour) != 'undefined' && homepagecolour != null) {
        new ScrollMagic.Scene({
                triggerElement: '#colour_carousel-swiper-container',
                triggerHook: 0.1,
                duration: '100%'
                        })

                        .on("enter", function () {document.getElementById('arrow2').classList.remove("opacity-50");
                                                        
                                                        document.getElementById('logo-link').classList.remove("pointer-events-auto");
                                                        document.getElementById('colour-header').classList.add("md:opacity-100");
                                                        document.getElementById('homepage-colour-link').classList.add("pointer-events-auto");
                                                        document.getElementById('colour-header-h1').classList.remove("homepage-flipper");
                                                        
                                                        })
                        .on("leave", function () {document.getElementById('arrow2').classList.add("opacity-50");
                                                        document.getElementById('colour-header').classList.remove("md:opacity-100");
                                                        document.getElementById('homepage-colour-link').classList.remove("pointer-events-auto");
                                                        document.getElementById('colour-header-h1').classList.add("homepage-flipper");     
                                                        })
                        
                        // .addIndicators({name: "Video " })
                        
                        .addTo(controller)
}

var homepagevfx = document.getElementById("vfx_carousel-swiper-container");
if (typeof(homepagevfx) != 'undefined' && homepagevfx != null) {
        new ScrollMagic.Scene({
                triggerElement: '#vfx_carousel-swiper-container',
                triggerHook: 0.1,
                duration: '100%'
                        })

                        .on("enter", function () {document.getElementById('arrow3').classList.remove("opacity-50");
                                                document.getElementById('vfx-header').classList.add("md:opacity-100");
                                                document.getElementById('homepage-vfx-link').classList.add("pointer-events-auto");
                                                document.getElementById('vfx-header-h1').classList.remove("homepage-flipper");
                                                        })
                        .on("leave", function () {document.getElementById('arrow3').classList.add("opacity-50");
                                                document.getElementById('vfx-header').classList.remove("md:opacity-100");
                                                document.getElementById('homepage-vfx-link').classList.remove("pointer-events-auto");
                                                document.getElementById('vfx-header-h1').classList.add("homepage-flipper");
                                                        })
                        
                        // .addIndicators({name: "Video " })
                        
                        .addTo(controller)


        new ScrollMagic.Scene({
                triggerElement: '#site-footer',// y value not modified, so we can use element as trigger as well
                duration: '100%'
                        })

                        .on("enter", function () {document.getElementById('arrows-container').classList.add("opacity-0")})
                        .on("leave", function () {document.getElementById('arrows-container').classList.remove("opacity-0")})
                        
                        // .addIndicators({name: "Video " })
                        
                        .addTo(controller)
}




// ----------------------------  Caption fade outs ----------------------------

var biocontainer = document.getElementById("bio");
if (typeof(biocontainer) != 'undefined' && biocontainer != null) {

        new ScrollMagic.Scene({
                triggerElement: '#bio',// y value not modified, so we can use element as trigger as well
                        })

                        .setClassToggle("#caption", "opacity-0")
                        .addTo(controller);

        new ScrollMagic.Scene({
                triggerElement: '#bio',// y value not modified, so we can use element as trigger as well
                        })

                        .setClassToggle("#caption-name", "opacity-0")
                        .addTo(controller);
                        
} else false; 

var colouristcaption = document.getElementById("colourists-caption");
if (typeof(colouristcaption) != 'undefined' && colouristcaption != null) {
        new ScrollMagic.Scene({
                triggerElement: '#site-footer',// y value not modified, so we can use element as trigger as well
                        })

                        .setClassToggle("#colourists-caption", "opacity-0")
                        .addTo(controller);

}







// ----------------------------  Swiper Carousel ----------------------------


var swiper_colour = new Swiper("#colour_carousel-swiper-container", {
        loop: true,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          speed: 1000,
          parallax: true,
      });
      
      

      
      
      /* swiper events */
      swiper_colour.on("slideChange", function() {
            var activeslide = swiper_colour.realIndex;
    
            var slides = document.getElementsByClassName("colour-swiper-slide");
            var i;
            for (i = 0; i < slides.length; i++) {
                var thisslide = slides[i].getAttribute("data-swiper-slide-index");
                var vid = document.getElementsByClassName("colour-carousel-vid");
                
                
                if (thisslide == activeslide) {
                    vid[i].play();
                } else {
                    vid[i].pause();
                }
            }
      });



      var swiper_vfx = new Swiper("#vfx_carousel-swiper-container", {
        loop: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
          },
          speed: 1000,
          parallax: true,
      });


      window.onload = function() {

        /* LazyLoad */
      var lazyLoadInstance = new LazyLoad({
        elements_selector: ".lazy",
        // ... more custom settings?
    });
    
    if (lazyLoadInstance) {
        lazyLoadInstance.update();
    }

      var lazyLoadInstance2 = new LazyLoad({
        elements_selector: ".lazy2",
        // ... more custom settings?
        });

        if (lazyLoadInstance2) {
                lazyLoadInstance2.update();
        }
    
      };    
    


      swiper_vfx.on("slideChange", function() {
        var activeslide = swiper_vfx.realIndex;

        var slides = document.getElementsByClassName("vfx-swiper-slide");
        var i;
        for (i = 0; i < slides.length; i++) {
            var thisslide = slides[i].getAttribute("data-swiper-slide-index");
            var vid = document.getElementsByClassName("vfx-carousel-vid");
            
            
            if (thisslide == activeslide) {
                vid[i].play();
            } else {
                vid[i].pause();
            }
        }
        });

      
